import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="Imprint / Impressum digital-smash.de" />
    <div className="container my-5">

      <div className="container">
        <h1 className="imprint my-4">What you need <br/> to know</h1>
        <h4>Angaben gemäß § 5 TMG</h4>
        <p>Peter Benyei <br/> digital smash</p>
          <ul className="list-unstyled">
            <li>Stresemannallee 8</li>
            <li>30173 Hannover</li>
            <li><a href="mailto:info@digital-smash.de">info@digital-smash.de</a></li>
          </ul>
        <h4>Urheberrecht:</h4>
        <p>
          Alle Rechte vorbehalten. Alle Texte, Bilder, Graphiken, Tonfolgen, Animationen und Videos und ihre Darstellung auf dieser Website sind  urheberrechtlich und durch andere Schutzrechte geschützt. Es ist nicht erlaubt, den Inhalt der Website zu gewerblichen Zwecken zu kopieren, verbreiten, ändern oder Dritten zugänglich zu machen. Einige Seiten können auch Bilder beinhalten die durch das Urheberrecht Dritter geschützt sind.
        </p>
      </div>
    </div>
  </Layout>
)

export default AboutPage
